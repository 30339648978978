
























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CoefficientConfig from "@/models/CoefficientConfig";
import FournitureReference from "@/models/FournitureReference";
import CoefficientService from "@/services/CoefficientService";
import UnitService from "@/services/UnitService";
import { Guid } from "guid-typescript";
import CoefficientSelectedValueService from "@/services/CoefficientSelectedValueService";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import { RubriqueEnum, CoefficientTypeEnum, UserRoleEnum } from "@/utils/Enums";
import OperationTypeService from "@/services/OperationTypeService";
import Constants from "@/utils/Constants";
type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};
const COEFFICIENT_NATURE_TACHE_KEY = "K2";
const FOURNITURE_OPERATION_FAMILY_KEY = "fournitures";
@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    OperationFamiliesModal: () =>
      import("@/components/Shared/Table/OperationFamiliesModal.vue"),
  },
})
export default class ReferenceFournitures extends Vue {
  @Prop({ required: true })
  private bopModelId!: string;

  private modalProps: any = null;
  public data: Data = {
    columns: [],
    rows: [],
  };
  public isModalVisible: boolean = false;
  private url: string = "";
  private modifiedReferenceFournitures: Array<FournitureReference> = [];
  private get language() {
    return this.$i18n.locale;
  }
  /**
   * Methods
   */
  public validate(obj: any) {
    //
    (<any>this.$refs.table).modify(obj);
    this.isModalVisible = false;
  }
  public openModal(props: any) {
    // console.log("Im called man");
    this.isModalVisible = true;
    this.modalProps = props;
  }
  public async save() {
    try {
      const response = await CoefficientOptionService.SetVisibleOperationFamilies(
        this.modifiedReferenceFournitures
      );
      if (response.status === 200) {
        this.$notify({
          group: "global",
          type: "success",
          title: this.$t(
            "bopModelSetup.referenceFourniture.saveSuccessTitle"
          ).toString(),
          text: this.$t(
            "bopModelSetup.referenceFourniture.saveSuccess"
          ).toString(),
        });
        this.refresh();
      }
    } catch {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t(
          "bopModelSetup.referenceFourniture.errorsTitle"
        ).toString(),
        text: this.$t(
          "bopModelSetup.referenceFourniture.incorrectValue"
        ).toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
    }
  }

  get isNotAllowedToEdit(): Boolean {
    return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator);
  }

  public refresh(): void {
    this.modifiedReferenceFournitures = [];
    (<any>this.$refs.table).refreshAPI();
    this.$store.commit("changeHasBeenModified", false);
  }

  /**
   * Hooks
   */
  public async beforeMount() {
    const coefficient = (
      await CoefficientService.getCoefficientByKeyAndBopModel(
        this.bopModelId,
        COEFFICIENT_NATURE_TACHE_KEY,
        this.language
      )
    ).data;

    this.url = CoefficientOptionService.GetVisibleOperationFamiliesUrl(
      coefficient.id,
      this.language
    );
    const operationFamilies = (
      await OperationTypeService.listOperationFamiliesByParentAndBopModel(
        this.bopModelId,
        FOURNITURE_OPERATION_FAMILY_KEY,
        this.language
      )
    ).data;
    const dataColumns: Array<any> = [
      {
        name: "editable",
        title: coefficient.name,
        sortField: `coefficientOptionName`,
        property: `coefficientOptionName`,
        isNotEditable: true,
      },
      {
        name: "operationFamilies",
        sortField: "operationFamilies",
        operationFamilies,
        title: this.$t("bopModelSetup.referenceFournitureOperationFamilies"),
        property: "operationFamilies",
      },
    ];

    this.data.columns = dataColumns;
  }
}
